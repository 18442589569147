@use '@angular/material' as mat;
@import '@angular/material/theming';

$primary-palette: (50 : #e3edf7,
  100 : #b9d1ec,
  200 : #8ab2e0,
  300 : #5b93d3,
  400 : #387cc9,
  500 : #1565c0,
  600 : #125dba,
  700 : #0f53b2,
  800 : #0c49aa,
  900 : #06379c,
  A100 : #c8d7ff,
  A200 : #95b2ff,
  A400 : #628dff,
  A700 : #487aff,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #ffffff,
  ));
$accent-palette: (50 : #fff1e0,
  100 : #feddb3,
  200 : #fdc680,
  300 : #fcaf4d,
  400 : #fc9d26,
  500 : #fb8c00,
  600 : #fa8400,
  700 : #fa7900,
  800 : #f96f00,
  900 : #f85c00,
  A100 : #ffffff,
  A200 : #fff1eb,
  A400 : #ffceb8,
  A700 : #ffbc9f,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #000000,
    600 : #000000,
    700 : #000000,
    800 : #000000,
    900 : #000000,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  ));
$warn-palette: (50 : #fce7e7,
  100 : #f7c4c2,
  200 : #f29c9a,
  300 : #ed7472,
  400 : #e95753,
  500 : #e53935,
  600 : #e23330,
  700 : #de2c28,
  800 : #da2422,
  900 : #d31716,
  A100 : #ffffff,
  A200 : #ffd1d1,
  A400 : #ff9f9e,
  A700 : #ff8585,
  contrast: (50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  ));

$app-primary: mat.define-palette($primary-palette);
$app-accent: mat.define-palette($accent-palette);
$app-warn: mat.define-palette($warn-palette);

$primary: mat.get-color-from-palette($app-primary);
$accent: mat.get-color-from-palette($app-accent);
$warn: mat.get-color-from-palette($app-warn);
$success: #28a745;

$background-color: #f0f2f7;
$background-color-light: #fff;
$background-color-hover: rgba(0, 0, 0, 0.04);
$border-color: #e0e0e0;
$text-normal: #333;
$text-hint: #616161;
$text-light: #fff;

$header-height: 48px;
$breadcrumb-height: 24px;
$breadcrumb-margin: 8px;
$main-padding: 16px;

$sidenav-width: 240px;
$page-top-offset: 112px;

$dialog-height-l: 65vh;
$dialog-width-l: 75vw;

@mixin mq-s {
  @media (max-width:1087px) {
    @content;
  }
}

@mixin app-card {
  background: $background-color-light;
  padding: 16px;
  box-sizing: border-box;

  &>h3 {
    margin: 0;
    color: $text-hint;
    font-size: 1.4rem;
    display: flex;
    align-items: center;

    &>i {
      margin-right: 4px;
    }
  }
}

@mixin table-header {
  height: 48px;
  display: flex;
  align-items: center;

  span.spacer {
    flex: 1 1 auto;
  }

  p.filter-text {
    font-size: 1.2rem;
    color: $primary;
    padding-left: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p.message {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.info {
      color: $primary;
    }

    &.error {
      color: $warn;
    }
  }
}

@mixin table-container {
  background-color: $background-color-light;
  border: solid 1px $border-color;
  box-sizing: border-box;
  position: relative;
  overflow: auto;

  &>table {
    width: 100%;

    .mat-cell.number {
      text-align: right;
      padding-right: 16px;
    }

    .mat-column-_options {
      border-left: solid 1px $border-color;
      padding-right: 8px;
    }
  }

  &>p.empty {
    margin: 64px;
    text-align: center;
    color: $text-hint;
  }

  &>div.loading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@mixin large-dialog {
  width: $dialog-width-l;
  height: $dialog-height-l;
}

@mixin attr-list($labelWidth: 144px) {
  div.attr {
    display: grid;
    align-items: center;
    gap: 8px;
    grid-template-columns: $labelWidth 1fr;

    span.label {
      color: $text-hint;
    }

    &:nth-of-type(n+2) {
      margin-top: 8px;
    }

    span.value {
      &.number {
        text-align: right;
        padding-right: 16px;
      }
      &.error {
        text-align: left;
        color: $warn;
      }
    }
  }
}

@mixin filter-items {
  margin: 0 0 16px 0;

  div.item {
    margin-top: 16px;
    display: grid;
    grid-template-columns: 144px 360px;
    align-items: center;

    &>span.label {
      color: $text-hint;
    }

    mat-radio-group {
      mat-radio-button:nth-of-type(n+2) {
        margin-left: 8px;
      }
    }
  }
}
