/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import '@angular/material/theming';
@import '@angular/cdk/overlay-prebuilt.css';
@import 'variables';

@include mat.core();
$custom-typography: mat.define-typography-config($font-family: '"Hiragino Sans W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif');
@include mat.all-component-typographies($custom-typography);
$custom-theme: mat.define-light-theme($app-primary, $app-accent, $app-warn);
@include mat.all-component-themes($custom-theme);

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-size: 1.4rem;
  font-family: "Hiragino Sans W3", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  color: $text-normal;
  background: $background-color;
  overflow-y: scroll;
}

p {
  margin: 0;
}

a {
  color: $primary;
  text-decoration: none;

  &:hover {
    color: lighten($primary, 20%);
  }
}

input {
  outline: none;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* Adjust styles for Angular Material */
.mat-table {

  .mat-header-cell {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 8px 0 8px;
    color: $text-hint;
  }

  .mat-cell {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0 8px 0 8px;
  }
}

.mat-paginator {
  background-color: initial;

  .mat-paginator-range-label {
    white-space: nowrap;
    margin-right: 16px;
  }
}

.mat-dialog-actions {
  justify-content: flex-end;
}

.mat-form-field {
  .mat-input-element:disabled {
    color: $text-normal;
  }

  .mat-select-disabled .mat-select-value {
    color: $text-normal !important;
  }

  .mat-chip-list .mat-chip-list-wrapper {
    flex-wrap: nowrap;

    input.mat-chip-input {
      width: 120px;
      flex-basis: 120px;
    }
  }

  .mat-error {
    padding-top: 2px;
  }
}

.mat-button-disabled {
  mat-icon {
    opacity: 0.5;
  }
}